@import "./variables";

#map-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

#map {
    transform: translateY(-50px);
    &.node-selected {

        .node:not(.selected):not(.linked) {
            opacity: .15 !important;
            transition: all 1s;
        }

        .node.selected {
            @extend .shadow;
        }
    }
}

body.inited .with-selection {
        .node:not(.selected):not(.linked) {
            opacity: .15 !important;
            transition: opacity .5s;
        }

        .node.selected {
            @extend .shadow;
        }
}

svg#map.stroke-bold path {
    stroke-width: 3px !important;
}

svg#map.stroke-bold line:not(.quadrillage) {
    stroke-width: 3px !important;
}

svg#map.stroke-bold line.quadrillage {
    stroke-width: 2px !important;
}

.node {
    stroke-width: $border-width;
    cursor: pointer;
    transition: opacity 1s;
    stroke: $noir;

    &:hover {
        @extend .shadow;
    }

    .fond {
        fill: $back;
    }

    &:focus {
        outline: none;
    }
}

.link {
    stroke: $noir;
    stroke-width: $border-width;
    @extend .shadow;
}

.quadrillage {
    stroke: #FFFFFF;
    stroke-width: 1px;
    stroke-opacity: .33;
}

.communaute-zone {

}

body.inited .soon {
    opacity: .35 !important;
}
