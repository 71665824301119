@import "variables";

.modale-tags {
    .logo-outer {
        width: 0;
    }

    &.open {
        max-height: 250px;

        .logo-outer {
            width: 300px;
            max-width: 90%;
        }
    }

    .content {
        max-height: 230px;
        p:not(:first-child) {
            margin-top: 20px;
        }

        p {
            font-family: "Nunito", sans-serif;
            font-size: 1.1rem;
        }
    }
}
