@import "variables";

.apropos {
    .logo-outer {
        width: 0;
    }

    &.open {
        .logo-outer {
            width: 300px;
            max-width: 90%;
        }
    }

    .content {
        p:not(:first-child) {
            margin-top: 20px;
        }

        p, .paragraph div {
            font-family: "Nunito", sans-serif;
            font-size: 1.1rem;
        }
    }
}


.communaute-description {
    display: flex;
    margin-bottom: 40px;

    .communaute-libelle {
        display: block;
        //    text-align: center;
    }

    .communaute-forme {
        //  text-align: center;
        margin: 20px 0px;
    }

    .communaute-content {
        padding-left: 30px;
    }

}

.modal-footer {
    text-align: center;
    width: 100%;
    padding: 40px 0 0 0;

    button {
        padding: 10px 20px;
        font-size: 1.1rem;
        border-radius: 10px;
        border: 2px solid #000;
        background: white;
        transition: all .25s;
        font-family: "TrioGrotesk", sans-serif;

        &:hover {
            background: $primary;
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 768px) {
    .communaute-forme svg {
        width: 40px;
    }
}
