@import "variables";

.modale {
    .content {
      .partenaires {
          ul {
              list-style: none;
              padding: 0 20px;

              li {
                  display: inline-block;
                  margin: 0 10px;

                  a {
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      img {
                          width: 150px;
                          margin-bottom: 10px;
                      }
                  }

              }
          }
      }
    }
}
