@import "./variables";

.navbar {
    position: absolute;
    bottom: 30px;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    opacity: 0;
    transition: opacity .5s;

    .navbar-left, .navbar-right {
        background-color: $blanc;
        border: $border-width-navbar solid #000;
        border-radius: $border-radius;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        min-height: 60px;
    }

    .navbar-left {
        width: calc(100% - 65px);
        margin: 0 15px 0 30px;
        display: flex;
        justify-content: center;

        &.details {
            transition: all .5s;
            min-height: 90px;
        }
    }

    .navbar-right {
        width: 72px;
        margin: 0 30px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        height: 64px;
    }

    .navbar-right, .commandes {
        img, svg {
            width: 35px;
            cursor: pointer;


            .cls-1 {
                transition: all .5s;
            }

            &:hover .cls-1, &:focus .cls-1 {
                transition: all .5s;
                fill: $primary !important;
            }
        }

        &.active .cls-1 {
            transition: all .5s;
            fill: $primary !important;
        }
    }

    .ligne-1 {
        width: 100%;
        display: flex;
        align-items: center;
        height: 60px;

        .partie-1 {
            width: 100%;
            display: flex;
            align-items: center;
            margin-right: 15px;
            height: 60px;
        }

        .partie-2 {
            width: 150px;

            &.partage {
                width: 60px;

                .commandes {
                    justify-content: flex-end;

                    img, svg {
                        width: 45px;
                    }

                    .partie-2 {
                        width: 60px;
                    }
                }
            }
        }


    }

    .logo {
        display: inline-block;
        vertical-align: super;
        margin-top: 5px;
        text-align: left;

        img, svg {
            height: 50px;
            cursor: pointer;
        }
    }

    .rechercher {
        margin: 0 20px;
        width: calc(100% - 40px);
    }

    .input-rechercher {
        font-size: 35px;
        border: none transparent;
        width: calc(100% - 20px);
        padding: 0 10px;
        font-family: 'TrioGrotesk', sans-serif;
    }

    .communaute-libelle {
        padding-left: 30px;
        display: inline-block;
        font-size: 18px;
        overflow: hidden;
        max-width: 200px;
        font-family: "TrioGrotesk", sans-serif;
        text-align: left;
    }

    .individu {
        display: inline-block;

        .communaute-forme {
            padding-left: 10px;
            display: inline-block;
            text-align: center;

            .fond {
                fill: $blanc;
            }

            svg {
                height: 40px;
                stroke: $noir;
                stroke-width: $border-width;
            }
        }

        .individu-prenom {
            padding-left: 10px;
            display: inline-block;
            text-align: left;
            font-size: 22px;
        }
    }

    .commandes {
        text-align: right;
        padding-right: 0;
        display: flex;
        gap: 15px;
    }

    .ligne-2 {
        width: 100%;
        margin-left: 15px;
        margin-bottom: 10px;

        .variation {
            margin-top: 15px;
            font-size: .8rem;
        }
    }

    &.details .ligne-2 {
        transition: all .5s;
        height: fit-content;

        .tag {
            transition: all .5s;
            height: auto;
            opacity: 1;
            line-height: 1;
            margin-bottom: 5px;
        }
    }
}

@media (max-width: $breakpoint) {
    .navbar {
        .logo {
            width: 80%;
            max-width: $breakpoint;
        }

        .commandes {
            width: 20%;
            text-align: right;

            img {
                width: 50%;
            }
        }

         .commandes {
            display: none;
        }
    }
}

.d-flex-center {
    display: flex;
    align-items: center;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 992px) {
    .navbar .logo img, .navbar .logo svg {
        height: 40px;
    }

    .navbar .communaute-libelle {
        padding-left: 15px;
        font-size: 14px;
    }

    .navbar .individu .communaute-forme svg {
        width: 40px;
    }

    .navbar .individu .individu-prenom {
        font-size: 16px;
    }

    .navbar .commandes {
        gap: 10px;
    }

    .navbar .navbar-right img, .navbar .navbar-right svg, .navbar .commandes img, .navbar .commandes svg {
        width: 28px;
    }
}

// Medium+ devices (tablets, 768px and up)
@media (min-width: 850px) and (max-width: 992px) {
    .navbar .logo img, .navbar .logo svg {
        height: 45px;
    }

    .navbar .communaute-libelle {
        font-size: 16px;
    }

    .navbar .individu .individu-prenom {
        font-size: 20px;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 768px) {
    .navbar .logo img, .navbar .logo svg {
        height: 40px;
    }

    .navbar .communaute-libelle {
        padding-left: 15px;
        font-size: 14px;
    }

    .navbar .individu .communaute-forme svg {
        width: 40px;
    }

    .navbar .individu .individu-prenom {
        font-size: 16px;
    }

    .navbar .commandes {
        gap: 10px;
    }

    .navbar .navbar-right img, .navbar .navbar-right svg, .navbar .commandes img, .navbar .commandes svg {
        width: 28px;
    }
}

.navbar-mobile {
    opacity: 0;
    transition: opacity .5s;

    .navbar-top {
        position: absolute;
        right: 15px;
        top: 15px;
        background-color: $blanc;
        border: $border-width-navbar solid #000;
        border-radius: $border-radius;
        padding: 0 10px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        img, svg {
            margin: 10px 0;
        }
    }

    .navbar-right {
        position: absolute;
        right: 15px;
        bottom: 95px;
        background-color: $blanc;
        border: $border-width-navbar solid #000;
        border-radius: $border-radius;
        padding: 0 10px;
        width: 40px;

        &.details {
            bottom: 120px;
        }

        img, svg {
            margin: 10px 0;
        }
    }

    .navbar-bottom {
        position: absolute;
        bottom: 15px;

        background-color: $blanc;
        border: $border-width-navbar solid #000;
        border-radius: $border-radius;
        padding: 0 10px;
        //  height: 60px;
        width: calc(100% - 55px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin: 0 15px;

        .ligne-1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        &.recherche .ligne-1 {
            flex-direction: column;
        }

        .ligne-2 {
            padding-left: 10px;
            margin-bottom: 10px;

            .communaute-libelle {
                font-size: .9rem;
            }
        }

        img.logo-noussommes, svg.logo-noussommes {
            height: 50px;
        }

        &.recherche {
            flex-direction: column;
            height: auto;
            max-height: 350px;

            .input-rechercher {
                width: calc(100% - 20px);
                margin: 5px 0;
            }

            .btn-rechercher {
                display: none;
            }
        }
    }

    img:not(.logo-noussommes), svg:not(.logo-noussommes) {
        height: 40px;

        .cls-1 {
            transition: all .5s;
        }

        &:hover .cls-1 {
            transition: all .5s;
            fill: $primary !important;
        }
    }

    .rechercher {
        margin: 0;
        width: 100%;
    }

    .propositions {
        max-height: 280px;
        overflow-y: auto;
        margin-bottom: 10px;

        .tag {
            margin: 5px 0;
        }
    }

    .input-rechercher {
        font-size: 35px;
        border: none transparent;
        width: calc(100% - 20px);
        padding: 0 10px;
        font-family: 'TrioGrotesk', sans-serif;
    }
}

.navbar-mobile-selection {
    opacity: 0;
    transition: opacity .5s;

    .navbar-bottom {
        width: calc(100% - 30px);
        position: absolute;
        bottom: 15px;
        margin: 0 15px;

        .navbar-bottom-ligne1-partie1, .navbar-bottom-ligne1-partie2, .navbar-bottom-ligne2 {
            background-color: $blanc;
            border: $border-width-navbar solid #000;
            border-radius: $border-radius;
            padding: 5px 10px;
        }

        .navbar-bottom-ligne1 {
            margin-bottom: 15px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            gap: 15px;

            .navbar-bottom-ligne1-partie1 {
                width: calc(100% - 90px - 12px);
                display: flex;
                flex-direction: column;

                svg, img {
                    height: 50px;
                    max-width: calc(100vw - 90px - 42px);
                }

                .communaute-libelle {
                    font-size: 18px;
                    font-family: "TrioGrotesk", sans-serif;
                    text-align: center;
                    margin-bottom: 5px;
                }
            }

            .navbar-bottom-ligne1-partie2 {
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                img, svg {
                    height: 40px;
                    width: 40px;
                }
            }
        }

        .navbar-bottom-ligne2 {
            display: flex;
            width: calc(100% - 30px + 4px - 60px - 15px);
            flex-direction: column;

            .individu {
                justify-content: center;
                width: 100%;

                img, svg {
                    max-height: 50px;
                    margin-right: 5px;
                }
            }

            .tags {
                text-align: center;

                .tag {
                    margin: 3px 10px !important;
                }
            }

            .variation {
                text-align: center;
                margin-top: 10px;
                font-size: .8rem;
            }
        }
    }
}

body.inited .navbar, body.inited .navbar-mobile, body.inited .navbar-mobile-selection {
    opacity: 1 !important;
}

button.active svg .cls-1 {
    transition: all .5s;
    fill: $primary !important;
}

.bientot {
    font-size: 12px;
}

/* smartphones, touchscreens */
@media (hover: none) and (pointer: coarse) {
    .navbar-mobile button:not(.active) svg:not(.logo-noussommes):hover .cls-1 {
        fill: $blanc !important;
    }
}

