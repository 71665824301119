@import "variables";

.modale-share {

    &.open {
        height: 60vh !important;
        max-height: 60vh !important;

        .content {
            height: calc(100vh - 250px);
        }
    }



    .liens {
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 40px;
        justify-content: center;
    }
}
