@import "variables";

#video-player {
    button {
        background: white;
        color: $noir;
        border: 2px solid $noir;
        border-radius: $border-radius;
        font-family: "TrioGrotesk", sans-serif;
        font-size: .9rem;

    }

    position: fixed;
    top: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    left: 0;
    height: 86vh;
    //  background-color: #FFFFFF66;

    .video {
        position: initial;
        border-radius: 10px;
        max-height: calc(100vh - 220px);
        outline: none;
    }

    iframe {
        position: initial;
        border-radius: 10px;
        //   border: 2px solid $noir;
        //  width: 640px;
        //  height: 360px;
    }

    button.srt {
        white-space: nowrap;

        &.active {
            background: $primary;
            color: black;
        }
    }

    .sub-video {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
        margin-top: 10px;

        .tag-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
        }

        button.tag {
            margin-top: 0;
            margin-bottom: 5px;
            margin-right: 10px;

            &:hover {
                background: $primary;
                color: black;
            }
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 768px) {
    #video-player {
        justify-content: start;
        margin-top: 10vh;
    }
}

// Sous-titres
//noinspection ALL
::cue {
    /* Change the font size */
    // font-size: 26px !important;
    font-family: "Verdana", sans-serif;


    /* Make the text have a shadow that simulates an outline */
    text-shadow: 4px 0px 0 rgba(0, 0, 0, 0.7),
    4px 1px 0 rgba(0, 0, 0, 0.7),
    3px 2px 0 rgba(0, 0, 0, 0.7),
    3px 3px 0 rgba(0, 0, 0, 0.7),
    2px 3px 0 rgba(0, 0, 0, 0.7),
    1px 4px 0 rgba(0, 0, 0, 0.7),
    0px 4px 0 rgba(0, 0, 0, 0.7),
    -1px 4px 0 rgba(0, 0, 0, 0.7),
    -2px 3px 0 rgba(0, 0, 0, 0.7),
    -3px 3px 0 rgba(0, 0, 0, 0.7),
    -3px 2px 0 rgba(0, 0, 0, 0.7),
    -4px 1px 0 rgba(0, 0, 0, 0.7),
    -4px 0px 0 rgba(0, 0, 0, 0.7),
    -4px -1px 0 rgba(0, 0, 0, 0.7),
    -3px -2px 0 rgba(0, 0, 0, 0.7),
    -3px -3px 0 rgba(0, 0, 0, 0.7),
    -2px -3px 0 rgba(0, 0, 0, 0.7),
    -1px -4px 0 rgba(0, 0, 0, 0.7),
    0px -4px 0 rgba(0, 0, 0, 0.7),
    1px -4px 0 rgba(0, 0, 0, 0.7),
    2px -3px 0 rgba(0, 0, 0, 0.7),
    3px -3px 0 rgba(0, 0, 0, 0.7),
    3px -2px 0 rgba(0, 0, 0, 0.7),
    4px -1px 0 rgba(0, 0, 0, 0.7);
}

//noinspection CssInvalidPseudoSelector
video::-webkit-media-text-track-display {
    /* Every -1.5em will push the subtitles up one line. The default is -1.5em, so my setting here will push subtitles up one line higher. */
    transform: translateY(-1.5em) !important;
}
