@import "variables";

.modale.apropos {
    .partenaires {
        a {
            text-decoration: none;
            color: $noir !important;
        }

        ul, ul li {
            margin-left: 0;
            padding-left: 0;
        }
    }

    hr {
        margin-top: 30px;
    }
}
