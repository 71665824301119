@import "variables";

.modale-back {
    position: fixed;
    top: 0;
    left: 0;
    background: transparent;
    z-index: -10;
    width: 0vw;
    height: 0vh;
    transition: all .5s;

    &.open {
        width: 100vw;
        height: 100vh;
        transition: all .5s;
        z-index: 10;
    }
}

.modale {
    background-color: $blanc;
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
    border: $border-width solid $noir;
    border-radius: 10px;
    opacity: 0;
    transition: all .5s;
    padding: 25px;
    color: #EEE;
    line-height: 1.25;

    .content {
        overflow-y: hidden;
    }

    .button-close {
        position: absolute;
        top: -20px;
        right: -17px;
        background-color: #FFF;
        font-size: 20px;
        cursor: pointer;
        margin-right: -5px;
        border: 2px solid #000;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        transition: all .5s;
        color: black;
        text-align: center;
        padding: 0;
        line-height: 54px;

        &:hover {
            background: $primary;
        }
    }

    &.open {
        width: calc(100% - 64px - 50px);
        max-width: 800px;
        height: calc(100vh - 200px);
        opacity: 1;
        z-index: 10;
        transition: all .5s;
        color: #000;
    }

    .content {
        height: calc(100vh - 200px);
        overflow-y: auto;
        padding-right: 15px;

        p:not(:first-child) {
            margin-top: 20px;
        }

        p {
            font-family: "Nunito", sans-serif;
            font-size: 1.1rem;
        }
    }
}

@media (max-width: 768px) {
    .modale {
        position: fixed;
        height: 60px;
        left: 15px;
        width: calc(100% - 84px);
        bottom: 15px;

        .button-close {
            right: -7px;
        }
    }

    .modale.open {
        left: 15px;
        width: calc(100% - 84px);
        bottom: 15px;
    }

    .modale .content {
        height: calc(100vh - 195px);
        width: calc(100% - 10px);
    }
}
