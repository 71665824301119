@import "./variables";

html, body, #root, #nous-sommes {
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
    font-family: 'TrioGrotesk', sans-serif;
}

body {
    background: $back url("../images/fond.png");
    background-size: cover;

    &:before {
        width: 100vw;
        height: 100vh;
        display: block;
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        content: " ";
    }
}

h1 {
    font-size: 24px !important;
    margin-top: 20px !important;
}

h2 {
    font-size: 20px !important;
    margin-top: 40px !important;
    margin-bottom: 20px !important;
}

h3 {
    font-size: 18px !important;
    margin-top: 15px !important;
}

h4 {
    font-size: 16px !important;
    margin-top: 25px !important;
    margin-bottom: 15px !important;
}

b {
    font-weight: 600 !important;
}

.tag {
    margin-right: 20px;
    display: inline-block;
    border: none;
    font-family: "TrioGrotesk", sans-serif;
    background: transparent;
    transition: all .5s;
    font-size: 1.1rem;
    color: black;
    text-align: left;

    &:hover {
        cursor: pointer;
        background-color: $primary;
        transition: all .25s;
        border-radius: 10px;
    }

    .communaute {
        // color: $primary;
        font-style: italic;
    }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #ffffff #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 10px;
    border: 2px solid #000000;
}

.btn-transparent {
    background: transparent;
    border: none;
    cursor: pointer;
    color: black;
    padding: 0;
    margin: 0;
    line-height: 1;
    font-family: "TrioGrotesk", sans-serif;
}

.text-center {
    text-align: center;
}

.pointer {
    cursor: pointer;
}

.social-media-name {
    font-size: .75rem;
    margin-top: 10px;
    display: inline-block;
}

.visually-hidden, .sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.realisation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 1000px;
    .realisation-item {
        width: 33%;
    }
}

.show-on-sm {
    display: none;
}

.hide-on-sm {
    display: block;
}

.w-100 {
    width: 100%;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 768px) {
    .realisation-item {
        width: 100% !important;
    }

    .show-on-sm {
        display: block !important;
    }

    .hide-on-sm {
        display: none !important;
    }
}


hr {
    border: none;
    border-top: 2px solid $noir;
    text-align: center;
}
