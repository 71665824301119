@import "~@julienbdx/nous-sommes-common/styles/common";

@font-face {
    font-family: "TrioGrotesk";
    src: url("../fonts/TrioGrotesk-Medium.woff2") format("woff2"),
    url("../fonts/TrioGrotesk-Medium.woff") format("woff"),
    url("../fonts/TrioGrotesk-Medium.eot") format("eot");
}

@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);

$border-width: 2px;
$border-width-navbar: 2px;
$border-radius: 10px;
$breakpoint: 650px;

.shadow {
    -webkit-filter: drop-shadow(5px 5px 3px rgba(0, 0, 0, .1));
    filter: drop-shadow(5px 5px 3px rgba(0, 0, 0, .1));
    transition: all .2s .02s;
}
