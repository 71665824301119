@import "./variables";

#wrapper {
    position: relative;
    height: 100vh;
    width: 100vw;
}

.profile-main-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 9000 !important;
}

.profile-main-loader .loader {
    margin: 0 auto;
    width: 200px;
    height: 200px;
}

.profile-main-loader .loader:before {
    content: '';
    display: block;
    padding-top: 100%;
}

.circular-loader {
    -webkit-animation: rotate 3s linear infinite;
    animation: rotate 3s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
}

.loader-path {
    display: none;
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 2.25s ease-in-out infinite, color 9s ease-in-out infinite;
    animation: dash 2.25s ease-in-out infinite, color 9s ease-in-out infinite;
    stroke-linecap: round;
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}

@keyframes color {
    0%, 100% {
        stroke: $noir;
        color: $noir;
    }
    50% {
        stroke: $han-purple;
        color: $han-purple;
    }
}

.message {
    animation: none;
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: -40px;

    span {
        animation: color 9s ease-in-out infinite;
        color: $mandy;
    }

    .logo-noussommes {
        width: 50vmin;
        margin-bottom: 20px;
    }
}
